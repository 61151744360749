import React, { createContext, useState, useEffect } from "react";
import { translations } from "../utils/translations";

// Crear el contexto
export const LanguageContext = createContext();

// Proveedor del contexto
export const LanguageProvider = ({ children }) => {
  // Estado para el idioma actual
  const [language, setLanguage] = useState("en"); // Idioma predeterminado: inglés

  // Detectar el idioma del navegador al cargar la aplicación
  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const detectedLanguage = userLanguage.startsWith("es") ? "es" : "en"; 
    setLanguage(detectedLanguage);
  }, []);
  

  // Función para obtener la traducción
  const t = (key) => translations[language][key];

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};