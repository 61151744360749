import React from 'react';
import { motion } from 'framer-motion';
import { FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  // Animaciones
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-gradient-to-br from-gray-900 py-8 lg:py-12"
    >
      {/* Contenedor principal */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4 lg:px-12"
      >
        {/* Bloque de información */}
        <motion.div
          variants={itemVariants}
          className="flex flex-col items-start"
        >
          <motion.img
            src="/images/logo.png"
            alt="Logo"
            className="w-32 mb-4"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          />
          <motion.address
            variants={itemVariants}
            className="not-italic text-blue-200 text-sm"
          >
            Uruguay, Maldonado
          </motion.address>
        </motion.div>

        {/* Bloque de contacto y redes sociales */}
        <motion.div
          variants={itemVariants}
          className="flex flex-col items-end"
        >
          <motion.a
            href="mailto:mppablomp@hotmail.com"
            className="text-green-400 hover:text-green-300 transition-colors duration-300 mb-2"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            mppablomp@hotmail.com
          </motion.a>
          <motion.div
            variants={itemVariants}
            className="flex space-x-4 mb-4"
          >
            {/* Enlace a Twitter */}
            <motion.a
              href="https://x.com/TenbyteGames"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              <FaTwitter className="w-6 h-6 text-blue-200 hover:text-blue-300 transition-colors duration-300" />
            </motion.a>
            {/* Enlace a Instagram */}
            <motion.a
              href="https://www.instagram.com/tenbyte_games/?igsh=ZjY5bG41Z2pmOXd0&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              <FaInstagram className="w-6 h-6 text-blue-200 hover:text-blue-300 transition-colors duration-300" />
            </motion.a>
          </motion.div>
        </motion.div>
      </motion.div>

      {/* Texto de derechos de autor */}
      <motion.div
        variants={itemVariants}
        className="text-center mt-8"
      >
        <p className="text-blue-200 text-sm">
          &copy; 2024 TenByte. All rights reserved.
        </p>
      </motion.div>
    </motion.footer>
  );
};

export default Footer;