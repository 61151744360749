export const translations = {
    en: {
      welcomeMessage: "Welcome to TenByte Games, where creativity and faith come together in every pixel. We are a game development studio founded by a Christian couple from Uruguay: Pablo, a video game designer, and Lucía, a teacher. Together, we create experiences that educate and entertain, blending playful mechanics with Christian values in every adventure.",
      donationText1: "Our games are completely free, and we want to keep them accessible to everyone. However, developing these games and maintaining our website involves costs that we cover with our own effort and resources. That's why we greatly appreciate any contribution you can provide.",
      donationText2: "Your donation will help us:",
      coverCosts: "Cover the costs of developing new video games.",
      maintainWebsite: "Maintain and improve our website.",
      continueMission: "Continue offering educational and quality content inspired by Christian values.",
      thankYouMessage: "We are grateful for your generosity and support, as it allows us to keep growing and be a blessing to many children, providing them with a safe and enriching space to learn and play. Thank you for being part of our mission at TenByte Games!",
      donateNow: "Donate Now",
      btnHOME: "HOME",
      btnABOUT: "ABOUT",
      btnGAMES: "GAMES",
      btnSTUDY:"BIBLE",
      welcomeTitle: "Welcome to TenByte",
      loginButton: "Sign In with Google",
      logoutButton: "Sign Out",
      termsAndConditions: "By signing in, you agree to our terms and conditions of use.",
      pointsLabel: "Accumulated Points",
      readingTimeLabel: "Reading Time",
      achievementsLabel: "Achievements",
      noAchievements: "None",
      title: "Leaderboard",
      minutes: "minutes",
      support: "Support Our Mission",
      makeaDonation: "Make a Donation",
      EveryContribution:"Every contribution counts and helps us continue our mission.",
      GameLibrary: "Game Library"
    },
    es: {
      welcomeMessage: "Bienvenido a TenByte Games, donde la creatividad y la fe se unen en cada píxel. Somos un estudio de desarrollo de juegos fundado por una pareja cristiana de Uruguay: Pablo, diseñador de videojuegos, y Lucía, profesora. Juntos creamos experiencias que educan y entretienen, combinando mecánicas divertidas con valores cristianos en cada aventura.",
      donationText1: "Nuestros juegos son completamente gratuitos, y queremos mantenerlos accesibles para todos. Sin embargo, desarrollar estos juegos y mantener nuestro sitio web conlleva costos que cubrimos con nuestro propio esfuerzo y recursos. Por eso, apreciamos enormemente cualquier contribución que puedas ofrecer.",
      donationText2: "Tu donación nos ayudará a:",
      coverCosts: "Cubrir los costos de desarrollo de nuevos videojuegos.",
      maintainWebsite: "Mantener y mejorar nuestro sitio web.",
      continueMission: "Continuar ofreciendo contenido educativo y de calidad inspirado en valores cristianos.",
      thankYouMessage: "Estamos agradecidos por tu generosidad y apoyo, ya que nos permite seguir creciendo y ser de bendición para muchos niños, brindándoles un espacio seguro y enriquecedor para aprender y jugar. ¡Gracias por ser parte de nuestra misión en TenByte Games!",
      donateNow: "Donar Ahora",
      btnHOME: "INICIO",
      btnABOUT: "ACERCA DE",
      btnGAMES: "JUEGOS",
      btnSTUDY:"BIBLIA",
      welcomeTitle: "Bienvenido a TenByte",
      loginButton: "Iniciar Sesión con Google",
      logoutButton: "Cerrar Sesión",
      termsAndConditions: "Al iniciar sesión, aceptas nuestros términos y condiciones de uso.",
      pointsLabel: "Puntos acumulados",
      readingTimeLabel: "Tiempo de lectura",
      achievementsLabel: "Logros",
      noAchievements: "Ninguno",
      title: "Ranking",
      minutes: "minutos",
      support: "Apoye nuestra misión",
      makeaDonation: "Haz una Donación",
      EveryContribution:"Cada contribución cuenta y nos ayuda a continuar con nuestra misión.",
      GameLibrary: "Biblioteca de Juegos"
    },
  };