import React from "react";
// Assuming you have Tailwind CSS or similar utility-first CSS framework setup

const Header = () => {
    return (
        <div className="w-full flex justify-between items-center px-4 py-2 bg-gradient-to-r from-gray-800 via-gray-900 to-black">
            {/* Logo */}
            <img 
                src="/images/logo.png" 
                alt="Logo" 
                className="h-10 transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            {/* Navigation Links */}
            
        </div>
    );
};

export default Header;