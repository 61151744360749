// src/services/index.js
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  onSnapshot, // Importa onSnapshot
} from "firebase/firestore"; // Importa funciones de Firestore
import { getStorage } from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Exporta servicios principales
export const db = getFirestore(app); // Firestore
export const storage = getStorage(app); // Storage
export const auth = getAuth(app); // Autenticación
export const googleProvider = new GoogleAuthProvider(); // Proveedor de Google

// Exporta funciones de autenticación
export { onAuthStateChanged, signOut };

// Exporta funciones de Firestore
export {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  onSnapshot, // Exporta onSnapshot
};