import React, { useState, useEffect } from 'react';
import biblia from '../services/biblia.json';
import kjva from '../services/KJVA.json';
import { saveReadingTime } from '../services/userServices'; // Importa saveReadingTime
import { auth, db, doc, onSnapshot } from '../services'; // Importa onSnapshot para escuchar cambios en tiempo real

const translations = {
  es: {
    selectBook: 'Seleccionar libro',
    chapter: 'Capítulo',
    noVerses: 'No se encontraron versículos',
    studyTime: 'Tiempo de estudio',
    start: 'Iniciar',
    pause: 'Pausar',
    stop: 'Detener',
    version: 'SpaRV: La Santa Biblia Reina-Valera (1909)',
    achievements: 'Logros',
    points: 'Puntos',
    leaderboard: 'Ranking',
  },
  en: {
    selectBook: 'Select Book',
    chapter: 'Chapter',
    noVerses: 'No verses found',
    studyTime: 'Study Time',
    start: 'Start',
    pause: 'Pause',
    stop: 'Stop',
    version: 'KJVA: King James Version (1769)',
    achievements: 'Achievements',
    points: 'Points',
    leaderboard: 'Leaderboard',
  },
};

const Bible = () => {
  const [selectedBook, setSelectedBook] = useState('');
  const [selectedChapter, setSelectedChapter] = useState(1);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null); // Estado para los datos del usuario
  const bibleData = selectedLanguage === 'es' ? biblia : kjva;
  const currentBook = bibleData.books.find((book) => book.name === selectedBook);

  // Escuchar cambios en la autenticación
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userRef = doc(db, "users", currentUser.uid);
        const unsubscribeSnapshot = onSnapshot(userRef, (docSnap) => {
          if (docSnap.exists()) {
            setUserData(docSnap.data()); // Actualiza los datos del usuario en tiempo real
          }
        });
        return () => unsubscribeSnapshot(); // Limpia el listener al desmontar el componente
      } else {
        setUser(null);
        setUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  const handleBookSelection = async (e) => {
    setSelectedBook(e.target.value);
    setSelectedChapter(1);
  };

  const handleStopTimer = async () => {
    setIsActive(false);
    if (user && timeElapsed > 0) {
      await saveReadingTime(user.uid, timeElapsed); // Guardar tiempo de lectura y puntos
      setTimeElapsed(0); // Reiniciar el cronómetro
    }
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setSelectedBook('');
    setSelectedChapter(1);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 min-h-screen text-white">
      <div className="text-center mb-6">
        <div className="flex justify-end mb-4">
          <select
            className="p-2 rounded-md bg-gray-800 border border-gray-700"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>
        </div>
        <h1 className="text-2xl font-bold text-purple-400 mb-2">
          Study with us - Free Online Bible
          <span className="block text-sm mt-1 text-gray-400">
            {translations[selectedLanguage].version}
          </span>
        </h1>
        {/* Gamificación */}
        {user && (
          <div className="bg-white/5 rounded-xl p-4 text-center space-y-4 mb-6">
            <div>
              <p className="text-gray-300">{translations[selectedLanguage].points}</p>
              <p className="text-3xl font-bold text-blue-400">
                {userData?.points || 0}
              </p>
            </div>
            <div>
              <p className="text-gray-300">{translations[selectedLanguage].achievements}</p>
              <p className="text-base text-yellow-400">
                {userData?.achievements?.join(", ") || "Ninguno"}
              </p>
            </div>
          </div>
        )}
        <div className="text-3xl font-mono mb-2">
          {translations[selectedLanguage].studyTime}: {Math.floor(timeElapsed / 60)}:{(timeElapsed % 60).toString().padStart(2, '0')}
        </div>
        <div className="space-x-2">
          <button
            onClick={() => setIsActive(!isActive)}
            className={`px-4 py-2 rounded-lg ${
              isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
            } transition-colors`}
          >
            {isActive ? translations[selectedLanguage].pause : translations[selectedLanguage].start}
          </button>
          <button
            onClick={handleStopTimer}
            className="bg-gray-700 px-4 py-2 rounded-lg hover:bg-gray-600 transition-colors"
          >
            {translations[selectedLanguage].stop}
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <select
          className="w-full md:w-1/2 p-2 rounded-md bg-gray-800 border border-gray-700 focus:ring-2 focus:ring-purple-500"
          value={selectedBook}
          onChange={handleBookSelection}
        >
          <option value="">{translations[selectedLanguage].selectBook}</option>
          {bibleData.books.map((book) => (
            <option key={book.name} value={book.name}>
              {book.name}
            </option>
          ))}
        </select>
        {currentBook && (
          <select
            className="w-full md:w-1/2 p-2 rounded-md bg-gray-800 border border-gray-700 focus:ring-2 focus:ring-purple-500"
            value={selectedChapter}
            onChange={(e) => setSelectedChapter(Number(e.target.value))}
          >
            {currentBook.chapters.map((chapter) => (
              <option key={chapter.chapter} value={chapter.chapter}>
                {translations[selectedLanguage].chapter} {chapter.chapter}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="space-y-4">
        {currentBook ? (
          <>
            <h2 className="text-xl font-bold mb-4 text-purple-300">
              {selectedBook} {selectedChapter}
            </h2>
            {currentBook.chapters
              .find((ch) => ch.chapter === selectedChapter)
              ?.verses.map((verse) => (
                <div
                  key={verse.verse}
                  className="p-4 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
                >
                  <span className="font-bold text-purple-400 mr-2">
                    {verse.verse}.
                  </span>
                  <span className="text-base md:text-lg text-center text-gray-300 max-w-2xl text-custom">
                    {verse.text}
                  </span>
                </div>
              ))}
          </>
        ) : (
          <p className="text-center text-gray-400 py-6">
            {translations[selectedLanguage].noVerses}
          </p>
        )}
      </div>
    </div>
  );
};

export default Bible;