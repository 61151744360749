import React, { useState, useEffect,useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { auth, googleProvider, db, doc, setDoc, getDoc } from '../services';
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { FcGoogle } from "react-icons/fc";
import { FiLogOut } from "react-icons/fi";
import { fetchLeaderboard } from "../services/userServices";
import { LanguageContext } from "../context/LanguageContext";

const Login = ({ onClose }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const { t } = useContext(LanguageContext);

  // Función para iniciar sesión con Google
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setUser(user);
      const userRef = doc(db, "users", user.uid);
      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          points: 0,
          achievements: [],
          readingTime: 0,
        });
      }
      const userData = userSnapshot.data();
      setUserData(userData);
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserData(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  // Escuchar cambios en la autenticación
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userRef = doc(db, "users", currentUser.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data());
        }
      } else {
        setUser(null);
        setUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Obtener el ranking de usuarios
  const fetchAndSetLeaderboard = async () => {
    const leaderboardData = await fetchLeaderboard();
    setLeaderboard(leaderboardData);
  };

  useEffect(() => {
    fetchAndSetLeaderboard();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 p-6">
      <div className="w-full max-w-md bg-white/10 backdrop-blur-lg rounded-2xl border border-white/20 p-6 sm:p-8 shadow-2xl overflow-y-auto max-h-[90vh]">
        {/* Título del Modal */}
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent"
        >
          {t("welcomeTitle")}
        </motion.h1>

        {/* Contenido del Modal */}
        <AnimatePresence>
          {user ? (
            // Si el usuario está logueado
            <motion.div
              key="user-info"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-6"
            >
              {/* Información del Usuario */}
              <div className="flex flex-col items-center">
                <motion.img
                  src={user.photoURL}
                  alt="Foto de perfil"
                  className="w-24 h-24 rounded-full border-4 border-white/20 shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                />
                <h2 className="text-xl font-semibold mt-4 text-white">
                  {user.displayName}
                </h2>
                <p className="text-gray-300 text-sm">{user.email}</p>
              </div>

              {/* Datos del Usuario */}
              <div className="bg-white/5 rounded-xl p-4 text-center space-y-4">
                <div>
                  <p className="text-gray-300">{t("pointsLabel")}</p>
                  <p className="text-3xl font-bold text-blue-400">
                    {userData?.points || 0}
                  </p>
                </div>
                <div>
                  <p className="text-gray-300">{t("readingTimeLabel")}</p>
                  <p className="text-3xl font-bold text-green-400">
                    {Math.floor((userData?.readingTime || 0) / 60)} minutos
                  </p>
                </div>
                <div>
                  <p className="text-gray-300">{t("achievementsLabel")}</p>
                  <p className="text-base text-yellow-400">
                    {userData?.achievements?.join(", ") || "Ninguno"}
                  </p>
                </div>
              </div>

              {/* Ranking */}
              <div className="bg-white/5 rounded-xl p-4 text-center">
                <p className="text-gray-300 mb-2">Ranking</p>
                <ul className="space-y-2 max-h-48 overflow-y-auto">
                  {leaderboard.map((entry, index) => (
                    <li key={index} className="text-lg text-white">
                      {index + 1}. {entry.displayName} -{" "}
                      {Math.floor(entry.readingTime / 60)} {t("minutes")}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Botón de Cerrar Sesión */}
              <motion.button
                onClick={handleLogout}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full flex items-center justify-center gap-2 bg-red-500/90 hover:bg-red-600 text-white px-6 py-3 rounded-lg transition-all duration-300"
              >
                <FiLogOut className="text-lg" />
                <span>{t("logoutButton")}</span>
              </motion.button>
            </motion.div>
          ) : (
            // Si el usuario no está logueado
            <motion.div
              key="login-form"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex flex-col items-center"
            >
              {/* Botón de Inicio de Sesión con Google */}
              <motion.button
                onClick={handleLogin}
                disabled={isLoading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full flex items-center justify-center gap-2 bg-white hover:bg-gray-100 text-gray-800 px-6 py-3 rounded-lg transition-all duration-300"
              >
                {isLoading ? (
                  <div className="w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    <FcGoogle className="text-xl" />
                    <span>{t("loginButton")}</span>
                  </>
                )}
              </motion.button>

              {/* Términos y Condiciones */}
              <p className="mt-4 text-sm text-gray-300 text-center">
                {t("termsAndConditions")}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Login;