import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { FiArrowDown } from "react-icons/fi";

const SectionWithVideo = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const yText = useTransform(scrollYProgress, [0, 1], [0, 200]);
  const opacityOverlay = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scaleVideo = useTransform(scrollYProgress, [0, 1], [1, 1.2]);

  return (
    <motion.div 
      ref={containerRef}
      className="relative w-full h-screen overflow-hidden"
    >
      <motion.video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/BG1.mp4"
        autoPlay
        loop
        muted
        playsInline
        style={{ scale: scaleVideo }}
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      />

      <motion.div
        className="absolute inset-0 bg-gradient-to-b from-blue-900/60 to-purple-900/40"
        style={{ opacity: opacityOverlay }}
      />
      <div className="absolute inset-0 bg-gradient-to-tr from-black/70 via-transparent to-black/70" />

      <motion.div
        className="relative z-10 flex flex-col items-center justify-center h-full text-center"
        style={{ y: yText }}
      >
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, type: "spring" }}
          className="text-5xl md:text-7xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent"
        >
          Play,{" "}
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
            className="text-blue-400"
          >
            Learn,
          </motion.span>
        </motion.h1>

        <motion.h2
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, type: "spring" }}
          className="mt-4 text-3xl md:text-5xl italic font-medium text-gray-300"
        >
          and be{" "}
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-blue-400 font-semibold"
          >
            Light.
          </motion.span>
        </motion.h2>

        <motion.div
          className="mt-16 flex flex-col items-center gap-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2 }}
        >
          <motion.div
            animate={{ y: [0, 15, 0] }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <FiArrowDown className="text-white text-3xl md:text-4xl" />
          </motion.div>
          <motion.p
            className="mt-2 text-sm text-gray-300 font-light tracking-wider"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.4 }}
          >
            Scroll to explore
          </motion.p>
        </motion.div>
      </motion.div>

      {/* Efecto de partículas sutiles */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute bg-white/10 rounded-full"
            style={{
              width: Math.random() * 10 + 2,
              height: Math.random() * 10 + 2,
              top: Math.random() * 100 + "%",
              left: Math.random() * 100 + "%",
            }}
            animate={{
              opacity: [0, 0.4, 0],
              scale: [0, 1.5, 0]
            }}
            transition={{
              duration: Math.random() * 4 + 3,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default SectionWithVideo;
