// src/services/userServices.js
import { 
    doc, 
    getDoc, 
    updateDoc, 
    collection, 
    query, 
    orderBy, 
    limit, 
    getDocs 
  } from "../services"; // Importa las funciones necesarias
  import { db } from "../services"; // Importa db desde ../services
  
  
  // Función para desbloquear logros
  export const unlockAchievement = async (userId, achievement) => {
    const userRef = doc(db, "users", userId); // Usa db aquí
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      const achievements = userSnapshot.data().achievements || [];
      if (!achievements.includes(achievement)) {
        await updateDoc(userRef, { achievements: [...achievements, achievement] });
      }
    }
  };
  
  // Función para guardar tiempo de lectura y verificar logros
  export const saveReadingTime = async (userId, timeInSeconds) => {
    const userRef = doc(db, "users", userId); // Usa db aquí
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      const currentReadingTime = userSnapshot.data().readingTime || 0;
      const newReadingTime = currentReadingTime + timeInSeconds;
  
      // Calcula los puntos acumulados (1 punto por minuto)
      const currentPoints = userSnapshot.data().points || 0;
      const pointsToAdd = Math.floor(timeInSeconds / 60); // 1 punto por minuto
      const newPoints = currentPoints + pointsToAdd;
  
      // Actualiza el tiempo de lectura y los puntos
      await updateDoc(userRef, { 
        readingTime: newReadingTime,
        points: newPoints
      });
  
      // Verifica y desbloquea logros basados en el tiempo de lectura
      await checkAchievements(userId, newReadingTime);
    }
  };
  
  // Función para verificar logros basados en tiempo de lectura
  export const checkAchievements = async (userId, readingTime) => {
    const achievementsToAdd = [];
  
    // Logros basados en tiempo de lectura
    if (readingTime < 3600) achievementsToAdd.push("Beginner Reader"); // Menos de 1 hora
    if (readingTime >= 3600 && readingTime < 10800) achievementsToAdd.push("Intermediate Reader"); // Entre 1 y 3 horas
    if (readingTime >= 10800 && readingTime < 172800) achievementsToAdd.push("Advanced Reader"); // Entre 3 y 48 horas
    if (readingTime >= 172800) achievementsToAdd.push("Biblical Master"); // Más de 48 horas
  
    // Desbloquear logros
    for (const achievement of achievementsToAdd) {
      await unlockAchievement(userId, achievement);
    }
  };
  
  // Función para obtener el ranking de usuarios
  export const fetchLeaderboard = async () => {
    const usersRef = collection(db, "users"); // Usa la función collection
    const q = query(usersRef, orderBy("readingTime", "desc"), limit(5)); // Usa query, orderBy y limit
    const querySnapshot = await getDocs(q); // Usa getDocs
  
    const leaderboard = [];
    querySnapshot.forEach((doc) => {
      leaderboard.push({ id: doc.id, ...doc.data() }); // Agrega cada usuario al ranking
    });
  
    return leaderboard;
  };