import React, { useContext } from "react";
import { motion } from 'framer-motion';
import { LanguageContext } from "../context/LanguageContext";

const Donations = () => {

  const { t } = useContext(LanguageContext);
  // Animaciones
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 100 },
    },
    hover: {
      y: -5,
      scale: 1.02,
      transition: { duration: 0.3 },
    },
    tap: { scale: 0.98 },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 p-6 lg:p-12"
    >
      {/* Título principal */}
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, type: 'spring' }}
        className="text-5xl md:text-6xl font-bold text-center mb-12 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent"
      >
        {t("support")}
      </motion.h1>

      {/* Contenedor principal con animación */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 md:grid-cols-2 gap-8"
      >
        {/* Bloque de texto con animación */}
        <motion.div
          variants={cardVariants}
          whileHover="hover"
          whileTap="tap"
          className="group relative overflow-hidden rounded-2xl bg-white/10 backdrop-blur-lg border border-white/20 shadow-2xl p-6"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <div className="relative z-10">
            <p className="mb-4 text-lg text-blue-200">
              {t("donationText1")}
            </p>
            <p className="mb-4 text-lg text-blue-200">
              {t("donationText2")}
            </p>
            <ul className="list-disc list-inside mb-4 text-lg text-blue-200">
              <li>{t("coverCosts")}</li>
              <li>{t("maintainWebsite")}</li>
              <li>{t("continueMission")}</li>
            </ul>
            <p className="text-lg text-blue-200">
              {t("thankYouMessage")}
            </p>
          </div>
        </motion.div>

        {/* Botón de donación con animación */}
        <motion.div
          variants={cardVariants}
          whileHover="hover"
          whileTap="tap"
          className="group relative overflow-hidden rounded-2xl bg-white/10 backdrop-blur-lg border border-white/20 shadow-2xl flex flex-col justify-center items-center p-6"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <div className="relative z-10 text-center">
            <h3 className="text-2xl font-bold text-white mb-4">{t("makeaDonation")}</h3>
            <p className="mb-6 text-blue-200">
              {t("EveryContribution")}
            </p>
            <motion.a
              href="https://www.paypal.com/donate/?hosted_button_id=8M3DSPLX423HC"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-400 hover:to-purple-500 text-white px-6 py-3 rounded-lg transition-all duration-300"
            >
              {t("donateNow")}
            </motion.a>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Donations;
