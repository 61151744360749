import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";
import Login from "./Login";
import { FiMenu, FiX, FiLogIn, FiChevronDown } from "react-icons/fi"; // Importa FiChevronDown
import { auth, onAuthStateChanged } from '../services';
import { LanguageContext } from "../context/LanguageContext"; // Importa el contexto de idioma

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false); // Nuevo estado
  const [user, setUser] = useState(null);
  const { t } = useContext(LanguageContext);

  // Acceder al contexto de idioma
  const { language, setLanguage } = useContext(LanguageContext);

  // Escuchar cambios en la autenticación
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { to: "#home", text: t("btnHOME") },
    { to: "#about", text: t("btnABOUT")},
    { to: "#games", text: t("btnGAMES") },
    { to: "#Biblia", text: t("btnSTUDY") },
  ];

  const mobileMenuVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "-100%" },
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  };

  return (
    <nav className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white w-full shadow-lg fixed top-0 z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo o nombre de la marca */}
          <div className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            TenByte Games
          </div>

          {/* Menú de navegación (Desktop) */}
          <div className="hidden md:flex space-x-6 items-center">
            {navLinks.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                smooth
                className="px-3 py-2 rounded-md text-lg font-medium hover:bg-gray-700 hover:text-white transition-all duration-300 relative group"
              >
                {link.text}
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-400 transition-all duration-300 group-hover:w-full" />
              </Link>
            ))}

            {/* Selector de idioma (Minimalista) */}
            <div className="relative">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="appearance-none bg-transparent text-gray-300 cursor-pointer focus:outline-none border-b border-gray-400 pb-1 pl-2 pr-6"
              >
                <option value="en" className="bg-gray-800 text-white">
                  EN
                </option>
                <option value="es" className="bg-gray-800 text-white">
                  ES
                </option>
              </select>
              <FiChevronDown className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>

            {/* Mostrar avatar o botón de login */}
            {user ? (
              <div className="flex items-center gap-3 cursor-pointer" onClick={() => setIsUserModalOpen(true)}>
                <div className="flex items-center gap-2">
                  <img
                    src={user.photoURL || "/default-avatar.png"}
                    alt={user.displayName}
                    className="w-8 h-8 rounded-full border-2 border-blue-400"
                  />
                  <span className="text-gray-200">{user.displayName}</span>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Evita que el clic en el botón afecte al contenedor
                    auth.signOut();
                  }}
                  className="px-3 py-1.5 text-sm bg-red-500 rounded-lg hover:bg-red-600 transition-all duration-300 flex items-center gap-2"
                >
                  <FiLogIn className="rotate-180" />
                  <span>{t("logoutButton")}</span>
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsLoginOpen(true)}
                className="px-4 py-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-300 flex items-center gap-2"
              >
                <FiLogIn className="text-xl" />
                <span>Login</span>
              </button>
            )}
          </div>

          {/* Botón de menú móvil */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="p-2 text-gray-400 hover:text-white focus:outline-none"
            >
              {isOpen ? (
                <FiX className="h-6 w-6" />
              ) : (
                <FiMenu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Menú de navegación (Mobile) */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenuVariants}
            transition={{ duration: 0.3 }}
            className="md:hidden absolute top-16 w-full bg-gray-900 shadow-lg"
          >
            <div className="px-2 pt-2 pb-4 space-y-1">
              {navLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  smooth
                  onClick={toggleMenu}
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 hover:text-white transition-all duration-300"
                >
                  {link.text}
                </Link>
              ))}

              {/* Selector de idioma (Mobile) */}
              <div className="relative px-3 py-2">
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="appearance-none bg-transparent text-gray-300 cursor-pointer focus:outline-none border-b border-gray-400 pb-1 pl-2 pr-6 w-full"
                >
                  <option value="en" className="bg-gray-800 text-white">
                    EN
                  </option>
                  <option value="es" className="bg-gray-800 text-white">
                    ES
                  </option>
                </select>
                <FiChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
              </div>

              {user ? (
                <div className="px-3 py-2">
                  <div
                    className="flex items-center gap-3 mb-3 cursor-pointer"
                    onClick={() => setIsUserModalOpen(true)}
                  >
                    <img
                      src={user.photoURL || "/default-avatar.png"}
                      alt={user.displayName}
                      className="w-8 h-8 rounded-full border-2 border-blue-400"
                    />
                    <span className="text-gray-200">{user.displayName}</span>
                  </div>
                  <button
                    onClick={() => auth.signOut()}
                    className="w-full px-4 py-2 bg-red-500 rounded-lg hover:bg-red-600 transition-all duration-300 flex items-center justify-center gap-2"
                  >
                    <FiLogIn className="rotate-180" />
                    <span>Logout</span>
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsLoginOpen(true);
                    toggleMenu();
                  }}
                  className="w-full px-4 py-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-300 flex items-center justify-center gap-2"
                >
                  <FiLogIn className="text-xl" />
                  <span>Login</span>
                </button>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Modal de Inicio de Sesión */}
      <AnimatePresence>
        {(isLoginOpen || isUserModalOpen) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-lg"
          >
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="bg-gray-900 p-6 sm:p-8 rounded-lg shadow-lg max-w-md w-full relative border border-white/10 overflow-y-auto max-h-[90vh]"
            >
              {/* Botón de cerrar */}
              <button
                onClick={() => {
                  setIsLoginOpen(false);
                  setIsUserModalOpen(false); // Cierra el modal del usuario
                }}
                className="absolute top-4 right-4 text-white hover:text-red-500 p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{
                  width: "48px", // Tamaño fijo para el botón
                  height: "48px", // Tamaño fijo para el botón
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FiX className="h-6 w-6" />
              </button>
              <Login onClose={() => {
                setIsLoginOpen(false);
                setIsUserModalOpen(false); // Cierra el modal del usuario
              }} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Nav;