import React, { useContext } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { LanguageContext } from "../context/LanguageContext"; // Importa el contexto de idioma

const Intro = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"],
  });

  const yTitle = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const yText = useTransform(scrollYProgress, [0, 1], [0, 150]);
  const scaleVideo = useTransform(scrollYProgress, [0, 1], [1, 1.1]);

  // Acceder al contexto de idioma
  const { t } = useContext(LanguageContext);

  return (
    <motion.div
      ref={containerRef}
      className="relative flex flex-col justify-center items-center min-h-screen p-4 overflow-hidden"
    >
      {/* Video de fondo */}
      <motion.video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/HouseVideo.mp4"
        autoPlay
        loop
        muted
        playsInline
        style={{ scale: scaleVideo }}
        initial={{ opacity: 0, scale: 1.2 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2, ease: "easeOut" }}
      />

      {/* Gradientes de fondo */}
      <div className="absolute inset-0 bg-gradient-to-b from-blue-900/60 to-purple-900/40" />
      <div className="absolute inset-0 bg-gradient-to-tr from-black/80 via-transparent to-black/80" />

      {/* Contenido principal */}
      <motion.div
        className="relative z-10 max-w-4xl px-4 space-y-8"
        style={{ y: yTitle }}
      >
        {/* Título (Texto estático, sin traducción) */}
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, type: "spring" }}
          className="text-5xl md:text-7xl font-bold text-center bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent drop-shadow-2xl"
        >
          TENBYTE GAMES
        </motion.h1>

        {/* Descripción (Texto traducido) */}
        <motion.div
          className="glass-container bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl"
          style={{ y: yText }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <motion.p
            className="text-lg md:text-xl text-center text-gray-300 leading-relaxed"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2 }}
          >
            {t("welcomeMessage")} {/* Traducción del mensaje de bienvenida */}
          </motion.p>
        </motion.div>
      </motion.div>

      {/* Efecto de partículas sutiles */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute bg-white/10 rounded-full"
            style={{
              width: Math.random() * 10 + 2,
              height: Math.random() * 10 + 2,
              top: Math.random() * 100 + "%",
              left: Math.random() * 100 + "%",
            }}
            animate={{
              opacity: [0, 0.4, 0],
              scale: [0, 1.5, 0],
            }}
            transition={{
              duration: Math.random() * 4 + 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Intro;